import { FC, ReactNode } from "react";
import classes from "./container.module.scss";

interface ContainerProps {
    children: ReactNode;
}

const Container: FC<ContainerProps> = ({ children }) => {
    return <div className={classes.wrapper}>{children}</div>;
};

export default Container;
