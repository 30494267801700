const QUARTERLY_UPDATE = `
    ... on ElementQuarterlyUpdatesSlide {
        id
        title
        html
        updates {
            nodes {
                id
                content
                objective
                subObjectives {
                    nodes {
                        id
                        objective
                        quarter1Page {
                            title
                            appRelativeLink
                        }
                        quarter1Type
                        quarter1Content
                        quarter1Image {
                            title
                            url
                            absoluteLink
                        }
                        quarter2Page {
                            title
                            appRelativeLink
                        }
                        quarter2Type
                        quarter2Content
                        quarter2Image {
                            title
                            url
                            absoluteLink
                        }
                        quarter3Page {
                            title
                            appRelativeLink
                        }
                        quarter3Type
                        quarter3Content
                        quarter3Image {
                            title
                            url
                            absoluteLink
                        }
                        quarter4Page {
                            title
                            appRelativeLink
                        }
                        quarter4Type
                        quarter4Content
                        quarter4Image {
                            title
                            url
                            absoluteLink
                        }
                    }
                }
            }
        }
    } `;

export default QUARTERLY_UPDATE;
