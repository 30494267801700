import { gql } from "@apollo/client";
import BAR_CHART from "utilities/graphQL/fragment/BarChart";
import COMPLEX_GRAPH from "utilities/graphQL/fragment/ComplexGraph";
import CONTENT from "utilities/graphQL/fragment/Content";
import FINANCIAL from "utilities/graphQL/fragment/Financial";
import FUNDING_ACTIVITIES from "utilities/graphQL/fragment/FundingActivities";
import IMAGE_CONTENT from "utilities/graphQL/fragment/ImageContent";
import PIE_CHART from "utilities/graphQL/fragment/PieChart";
import PROJECT_TRACKER from "utilities/graphQL/fragment/ProjectTracker";
import QUARTERLY_UPDATE from "utilities/graphQL/fragment/QuarterlyUpdate";

const PAGE = gql`
    # Write your query or mutation here
    query ReadPage($id: ID, $url: String) {
        readPages(id: $id, url: $url) {
            edges {
                node {
                    id
                    breadcrumbNavigationList {
                        title
                        link
                        type
                    }
                    elementalArea {
                        blocks {
                            edges {
                                node {
                                    __typename
                                    id
                                    ${IMAGE_CONTENT}
                                    ${PROJECT_TRACKER}
                                    ${FINANCIAL}
                                    ${BAR_CHART}
                                    ${COMPLEX_GRAPH}
                                    ${CONTENT}
                                    ${FUNDING_ACTIVITIES}
                                    ${PIE_CHART}
                                    ${QUARTERLY_UPDATE}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default PAGE;
