import { gql } from "@apollo/client";

const NAVIGATION = gql`
    query readNavigations {
        readNavigation {
            id
            title
            link
            absoluteLink
            type
            navigationIcon
            navigationDescription
            appRelativeLink
            children {
                id
                title
                link
                appRelativeLink
                navigationIcon
                navigationDescription
            }
        }
    }
`;

export default NAVIGATION;
