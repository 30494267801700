import { FC, Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import classes from "./breadcrumb.module.scss";

interface BreadcrumbProps {
    breadcrumbs?: any[];
}

const Breadcrumb: FC<BreadcrumbProps> = ({ breadcrumbs }) => {
    const router = useLocation();
    const [url, setUrl] = useState<string>("");
    const [local, setLocal] = useLocalStorage("carousel", false);

    useEffect(() => {
        setUrl(router.pathname.split("/")[1]);
    }, [router]);

    const handleOnClick = () => {
        setLocal(true);
    };

    if (breadcrumbs) {
        return (
            <div className={classes.wrapper}>
                {breadcrumbs.map((breadcrumb, index) => {
                    if (breadcrumb.type) {
                        return (
                            <Fragment key={`bc_${index}`}>
                                <span>{breadcrumb.title}</span>
                                {index < breadcrumbs.length - 1 && (
                                    <span>/</span>
                                )}
                            </Fragment>
                        );
                    }
                    return (
                        <Fragment key={`bc_${index}`}>
                            <Link
                                to={breadcrumb.link}
                                className={classes.link}
                                onClick={handleOnClick}
                            >
                                {breadcrumb.title}
                            </Link>
                            {index < breadcrumbs.length - 1 && <span>/</span>}
                        </Fragment>
                    );
                })}
            </div>
        );
    }
    return null;
};

export default Breadcrumb;
