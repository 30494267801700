import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
    uri: "https://apiquarterlyreport.toifoundation.org.nz/lite/graphql",
    // uri: "https://dev.smokeylemon.com/~toiprese/lite/graphql",
    // uri: "http://presentation.toifoundation.org.nz/lite/graphql",
    cache: new InMemoryCache(),
});

export default client;
