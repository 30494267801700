import { FC } from "react";
import classNames from "classnames/bind";
import { BsFillDashCircleFill } from "react-icons/bs";
import classes from "./status.module.scss";

interface QuarterlyStatusProps {
    type: string;
    content?: string;
    image?: any;
    setPopup?: Function;
}

const QuarterlyStatus: FC<QuarterlyStatusProps> = ({
    type,
    content,
    image,
    setPopup,
}) => {
    let cx = classNames.bind(classes);

    let tableCol = cx({
        wrapper: true,
        complete: type === "Complete",
        inprogress: type === "InProgress",
        behind: type === "BehindSchedule",
        notstarted: type === "NotStarted",
    });

    return (
        <>
            {type !== "None" && (
                <div
                    className={tableCol}
                    onClick={() => {
                        if (setPopup && content) {
                            setPopup({
                                image: image?.absoluteLink,
                                content: content,
                            });
                        }
                    }}
                >
                    <BsFillDashCircleFill size={25} />
                </div>
            )}
        </>
    );
};

export default QuarterlyStatus;
