const IMAGE_CONTENT = `
    ... on ElementImageContent {
        id
        title
        customAlignment
        html
        images {
            edges {
                node {
                    title
                    name
                    url
                    absoluteLink
                    width
                    height
                }
            }
        }
        actionButton {
            title
            type
            url
            email
            phone
            openInNewWindow
            linkURL
        }
    } `;

export default IMAGE_CONTENT;
