import { FC } from "react";
import nl2br from "react-nl2br";
import InterfaceQuarterlyUpdate from "utilities/interface/elements/quarterlyUpdate.interface";
import TemplateTable from "shared/Template/Table/Table";
import QuarterlyStatus from "./QuarterlyStatus/QuarterlyStatus";
import classes from "./element.module.scss";

interface ElementQuarterlyUpdateProps {
    element: InterfaceQuarterlyUpdate;
    setPopup?: Function;
}

const ElementQuarterlyUpdate: FC<ElementQuarterlyUpdateProps> = ({
    element,
    setPopup,
}) => {
    const { title, updates, html } = element;

    return (
        <TemplateTable
            heading={title}
            table={
                <div className={classes.tableWrapper}>
                    <table>
                        <tr>
                            <th>Context</th>
                            <th>Objectives</th>
                            <th className={classes.milestone}>Milestone</th>
                            <th className={classes.quarterCell}>Q1</th>
                            <th className={classes.quarterCell}>Q2</th>
                            <th className={classes.quarterCell}>Q3</th>
                            <th className={classes.quarterCell}>Q4</th>
                        </tr>
                        {updates?.nodes.map((item: any) => {
                            const { id, content, objective, subObjectives } =
                                item;
                            return (
                                <tr key={id}>
                                    <td>{nl2br(content)}</td>
                                    <td>{nl2br(objective)}</td>
                                    <td
                                        colSpan={5}
                                        className={classes.noPadding}
                                    >
                                        <table>
                                            {subObjectives.nodes.map(
                                                (item: any) => {
                                                    const {
                                                        id,
                                                        objective,
                                                        quarter1Type,
                                                        quarter1Content,
                                                        quarter1Image,
                                                        quarter2Type,
                                                        quarter2Content,
                                                        quarter2Image,
                                                        quarter3Type,
                                                        quarter3Content,
                                                        quarter3Image,
                                                        quarter4Type,
                                                        quarter4Content,
                                                        quarter4Image,
                                                    } = item;

                                                    let popupContent = "";
                                                    let popupImage = "";
                                                    if (quarter1Content) {
                                                        popupContent =
                                                            quarter1Content;
                                                    } else if (
                                                        quarter2Content
                                                    ) {
                                                        popupContent =
                                                            quarter2Content;
                                                    } else if (
                                                        quarter3Content
                                                    ) {
                                                        popupContent =
                                                            quarter3Content;
                                                    } else if (
                                                        quarter4Content
                                                    ) {
                                                        popupContent =
                                                            quarter4Content;
                                                    }

                                                    if (quarter1Image) {
                                                        popupImage =
                                                            quarter1Image.absoluteLink;
                                                    } else if (quarter2Image) {
                                                        popupImage =
                                                            quarter2Image.absoluteLink;
                                                    } else if (quarter3Image) {
                                                        popupImage =
                                                            quarter3Image.absoluteLink;
                                                    } else if (quarter4Image) {
                                                        popupImage =
                                                            quarter4Image.absoluteLink;
                                                    }

                                                    return (
                                                        <tr key={id}>
                                                            <td
                                                                className={
                                                                    classes.milestone
                                                                }
                                                            >
                                                                {popupContent ||
                                                                popupImage ? (
                                                                    <span
                                                                        className={
                                                                            classes.popupTrigger
                                                                        }
                                                                        onClick={() => {
                                                                            if (
                                                                                setPopup
                                                                            ) {
                                                                                setPopup(
                                                                                    {
                                                                                        image: popupImage,
                                                                                        content:
                                                                                            popupContent,
                                                                                    }
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            objective
                                                                        }
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        {
                                                                            objective
                                                                        }
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td
                                                                className={`${classes.quarterCell} ${classes.noPaddingX}`}
                                                            >
                                                                <QuarterlyStatus
                                                                    content={
                                                                        quarter1Content
                                                                    }
                                                                    type={
                                                                        quarter1Type
                                                                    }
                                                                    image={
                                                                        quarter1Image
                                                                    }
                                                                    setPopup={
                                                                        setPopup
                                                                    }
                                                                />
                                                            </td>
                                                            <td
                                                                className={`${classes.quarterCell} ${classes.noPaddingX}`}
                                                            >
                                                                <QuarterlyStatus
                                                                    content={
                                                                        quarter2Content
                                                                    }
                                                                    type={
                                                                        quarter2Type
                                                                    }
                                                                    image={
                                                                        quarter2Image
                                                                    }
                                                                    setPopup={
                                                                        setPopup
                                                                    }
                                                                />
                                                            </td>
                                                            <td
                                                                className={`${classes.quarterCell} ${classes.noPaddingX}`}
                                                            >
                                                                <QuarterlyStatus
                                                                    content={
                                                                        quarter3Content
                                                                    }
                                                                    type={
                                                                        quarter3Type
                                                                    }
                                                                    image={
                                                                        quarter3Image
                                                                    }
                                                                    setPopup={
                                                                        setPopup
                                                                    }
                                                                />
                                                            </td>
                                                            <td
                                                                className={`${classes.quarterCell} ${classes.noPaddingX}`}
                                                            >
                                                                <QuarterlyStatus
                                                                    content={
                                                                        quarter4Content
                                                                    }
                                                                    type={
                                                                        quarter4Type
                                                                    }
                                                                    image={
                                                                        quarter4Image
                                                                    }
                                                                    setPopup={
                                                                        setPopup
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </table>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            }
        >
            {element?.html && (
                <div className={classes.wrapper}>
                    <div className={classes.wrapperInner}>
                        <div className={classes.cols}>
                            {!!element?.html && (
                                <div
                                    className={`${classes.col} ${classes.colFull}`}
                                    dangerouslySetInnerHTML={{
                                        __html: html,
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </TemplateTable>
    );
};

export default ElementQuarterlyUpdate;
