import { gql } from "@apollo/client";

const VERIFY_LOGIN = gql`
    mutation VerifyAndLogin($email: String!, $otp: String!) {
        verifyAndLogin(email: $email, otp: $otp) {
            valid
            token
        }
    }
`;

export default VERIFY_LOGIN;
