import { gql } from "@apollo/client";

const LANDING_PAGE = gql`
    # Write your query or mutation here
    query ReadPage {
        readLandingPage {
            id
            title
            subTitle
            backgroundImage {
                absoluteLink
            }
            video {
                absoluteLink
            }
            videoThumb {
                absoluteLink
            }
        }
    }
`;

export default LANDING_PAGE;
