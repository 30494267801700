import { useEffect, useRef, useState } from "react";
import client from "utilities/apollo/client";
import NAVIGATION from "utilities/graphQL/queries/Navigation/navigation";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Container from "shared/Container/Container";
import Dropdown from "./Dropdown/Dropdown";
import HeaderMobile from "./Mobile/Mobile";
import logo from "assets/pictures/logo.png";
import classes from "./header.module.scss";

const Header = () => {
    const ref = useRef(null);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedDropdown, setSelectedDropdown] = useState<number>(-1);
    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        const handleFetchNavigation = async () => {
            const { data } = await client.query({
                query: NAVIGATION,
            });
            setList(data.readNavigation);
        };

        handleFetchNavigation();
    }, []);

    return (
        <header className={classes.wrapper}>
            <Container>
                <nav className={classes.cover}>
                    <Link to="/" className={classes.logo}>
                        <img src={logo} alt="logo" />
                    </Link>
                    <ul className={classes.list}>
                        {list?.slice(1).map((listItem: any) => {
                            return (
                                <li
                                    key={listItem.id}
                                    className={
                                        listItem.id === selectedDropdown
                                            ? `${classes.listItem} ${classes.active}`
                                            : classes.listItem
                                    }
                                    onMouseEnter={() => {
                                        setSelectedDropdown(listItem.id);
                                    }}
                                    onMouseLeave={() => {
                                        setSelectedDropdown(-1);
                                    }}
                                >
                                    {listItem.title}
                                    <div className={classes.dropdown}>
                                        <Dropdown
                                            data={listItem.children}
                                            dropdownChange={setSelectedDropdown}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    <span
                        className={classes.hamburger}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <GiHamburgerMenu size={35} />
                    </span>
                </nav>
            </Container>

            <div
                ref={ref}
                className={
                    open ? classes.mobileDropdownActive : classes.mobileDropdown
                }
            >
                <Container>
                    <HeaderMobile list={list} setOpen={setOpen} />
                </Container>
            </div>
        </header>
    );
};

export default Header;
