import { FC } from "react";
import InterfaceContent from "utilities/interface/elements/content.interface";
import TemplateContent from "shared/Template/Content/Content";
import CardContent from "shared/Card/Content/Content";
import classes from "./element.module.scss";

interface ElementContentProps {
    element: InterfaceContent;
}

const ElementContent: FC<ElementContentProps> = ({ element }) => {
    const { title, html, secondaryHTML } = element;

    return (
        <TemplateContent heading={title} content={html}>
            <div className={classes.wrapper}>
                <CardContent data={secondaryHTML} />
            </div>
        </TemplateContent>
    );
};

export default ElementContent;
