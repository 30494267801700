import {FC, useState} from "react";
import Dropdown from "../Dropdown/Dropdown";
import classes from "./dropdown.module.scss";

interface HeaderMobileProps {
    list: any[];
    setOpen: Function
}

const HeaderMobile: FC<HeaderMobileProps> = ({ list, setOpen }) => {

    const [selectedDropdown, setSelectedDropDown] = useState(-1);

    return (
        <ul className={classes.list}>
            {list?.slice(1, 5).map((listItem: any) => (
                <li key={listItem.id}
                    className={selectedDropdown === listItem.id ? `${classes.listItem} ${classes.listItemActive}` : `${classes.listItem}`}
                    onClick={() => {
                        if (selectedDropdown === listItem.id) {
                            setSelectedDropDown(-1);
                        } else {
                            setSelectedDropDown(listItem.id);
                        }
                }}>
                    {listItem.title}
                    <div className={classes.dropdown}>
                        <Dropdown data={listItem.children} setOpen={setOpen} />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default HeaderMobile;
