import { FC, useEffect } from "react";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import { useLocalStorage } from "usehooks-ts";
import Carousel from "react-multi-carousel";
import classes from "./carousal.module.scss";

interface CarousalProps {
    children: any;
}

const Carousal: FC<CarousalProps> = ({ children }) => {
    const responsive = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024,
            },
            items: 1,
        },

        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <div className={classes.wrapper}>
            <Carousel
                responsive={responsive}
                className={classes.carousal}
                draggable
                swipeable
                customTransition="all .5"
                transitionDuration={500}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                slidesToSlide={1}
                arrows={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
            >
                {children.map((child: any, index: any) => {
                    return (
                        <div
                            key={`slide__${index}`}
                            className={`${classes.slideItem} slide-container`}
                        >
                            {child}
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default Carousal;

export const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    const {
        carouselState: { currentSlide, totalItems },
    } = rest;

    const [local, setLocal] = useLocalStorage("carousel", false);

    useEffect(() => {
        local && goToSlide(0);
    }, [goToSlide, local]);

    return (
        <div className={classes.buttonWrapper}>
            {currentSlide > 0 && (
                <div className={classes.prev}>
                    <button
                        onClick={() => {
                            previous();
                            setLocal(false);
                        }}
                    >
                        <BsCaretLeftFill size={30} />
                    </button>
                    <span>Prev Page</span>
                </div>
            )}

            {currentSlide + 1 !== totalItems && (
                <div className={classes.next}>
                    <button
                        onClick={() => {
                            next();
                            setLocal(false);
                        }}
                    >
                        <BsCaretRightFill size={30} />
                    </button>
                    <span>Next Page</span>
                </div>
            )}
        </div>
    );
};
