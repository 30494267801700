import { FC } from "react";
import classes from "./card.module.scss";

interface CardContentProps {
    data: any;
}

const CardContent: FC<CardContentProps> = ({ data }) => {
    return (
        <div className={`${classes.content} html-text`}>
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </div>
    );
};

export default CardContent;
