import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import VERIFY_LOGIN from "utilities/graphQL/mutations/verify";
import classes from "./form.module.scss";

interface FormData {
    otp: string;
}

const schema = yup
    .object({
        otp: yup.string().required("OTP code must be required"),
    })
    .required();

interface VerifyProps {
    email: string;
}

const Verify: FC<VerifyProps> = ({ email }) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const [error, setError] = useState(false);
    const [verifyAndLogin] = useMutation(VERIFY_LOGIN);

    const onSubmit = (formData: FormData) => {
        const { otp } = formData;

        verifyAndLogin({ variables: { email, otp } }).then((res) => {
            if (res.data.verifyAndLogin.valid) {
                setError(false);
                localStorage.setItem(
                    "auth",
                    JSON.stringify(res.data.verifyAndLogin)
                );

                navigate("/");
            } else {
                setError(true);
            }
        });
    };

    return (
        <form
            id="formVerify"
            name="formVerify"
            noValidate
            onSubmit={(event) => {
                event?.stopPropagation();
                handleSubmit(async (values, event) => {
                    onSubmit(values as FormData);
                })(event);
            }}
        >
            <div className={classes.wrapper}>
                <h1>TOI FOUNDATION</h1>
                <h3>Verification Code</h3>

                <div>
                    <input
                        type="text"
                        placeholder="Go to your email for verification code"
                        {...register("otp")}
                    />
                    <span>{errors?.otp?.message}</span>
                </div>

                {error && (
                    <span className={classes.error}>
                        Please enter the correct OTP code
                    </span>
                )}

                <button form="formVerify" type="submit" className="btn-primary">
                    Submit
                </button>
            </div>
        </form>
    );
};

export default Verify;
