import { useState } from "react";
import Background from "shared/Background/Background";
import Form from "./Form/Form";
import Verify from "./Verify/Verify";
import background from "assets/pictures/background.jpg";
import classes from "./page.module.scss";

const PageLogin = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState<string>("");

    return (
        <Background bgImage={background}>
            <div className={classes.wrapper}>
                {!open ? (
                    <Form setOpen={setOpen} setEmail={setEmail} />
                ) : (
                    <Verify email={email} />
                )}
            </div>
        </Background>
    );
};

export default PageLogin;
