const PIE_CHART = `
    ... on ElementPieChartSlide {
        id
        title
        subTitle
        slideDescription
        legend
        chart {
            title
            name
            url
            absoluteLink
            width
            height
        }
    } `;

export default PIE_CHART;
