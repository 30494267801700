import { FC, useEffect, useState } from "react";
import { useReadLocalStorage } from "usehooks-ts";
import client from "utilities/apollo/client";
import PAGE from "utilities/graphQL/queries/page/page";
import InterfaceBreadcrumb from "utilities/interface/breadcrumb.interface";
import Layout from "shared/Layout/Layout";
import PageLoader from "shared/PageLoader/PageLoader";
import Container from "shared/Container/Container";
import Breadcrumb from "shared/Breadcrumb/Breadcrumb";
import Elements from "Elements/Elements";
import classes from "./page.module.scss";

interface PageSectionProps {
    pageId: string;
}

const PageSection: FC<PageSectionProps> = ({ pageId }) => {
    const [loading, setLoading] = useState(false);
    const [elements, setElements] = useState<any[]>();
    const [breadcrumbs, setBreadcrumbs] = useState<any[]>();

    let local = useReadLocalStorage("breadcrumb") as InterfaceBreadcrumb;
    if (!local) {
        local = {} as InterfaceBreadcrumb;
    }

    useEffect(() => {
        setLoading(true);
        const handleFetchPage = async () => {
            const { data } = await client.query({
                query: PAGE,
                variables: { url: pageId },
            });

            setElements(
                data.readPages.edges[0].node.elementalArea.blocks.edges
            );
            setBreadcrumbs(
                data.readPages.edges[0].node.breadcrumbNavigationList
            );
            setLoading(false);
        };

        handleFetchPage();
    }, [pageId]);

    if (loading) {
        return <PageLoader loading={loading} />;
    }

    return (
        <Layout>
            <div className={classes.wrapper}>
                <Container>
                    <Breadcrumb breadcrumbs={breadcrumbs} />
                    {elements && <Elements data={elements} />}
                </Container>
            </div>
        </Layout>
    );
};

export default PageSection;
