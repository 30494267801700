const BAR_CHART = `
    ... on ElementBarChartSlide {
        id
        title
        subTitle
        slideDescription
        secondaryDescription
        barChart {
            title
            name
            url
            absoluteLink
            width
            height
        }
    } `;

export default BAR_CHART;
