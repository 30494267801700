import { FC } from "react";
import classes from "./status.module.scss";

interface StatusProps {
    heading: string;
    range: string;
}

const Status: FC<StatusProps> = ({ heading, range }) => {
    return (
        <div className={classes.wrapper}>
            <h1>{heading}</h1>
            <span>
                <h4>{range}</h4>
            </span>
        </div>
    );
};

export default Status;
