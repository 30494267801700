import { FC, ReactNode } from "react";
import classes from "./template.module.scss";

interface TemplateContentProps {
    heading?: string;
    subHeading?: string;
    content?: any;
    children?: ReactNode;
}

const TemplateContent: FC<TemplateContentProps> = ({
    heading,
    subHeading,
    content,
    children,
}) => {
    return (
        <div className={classes.wrapper}>
            <h1>{heading}</h1>
            {/* {subHeading && <h2>{subHeading}</h2>} */}
            <div className={classes.slider}>
                <div className={`${classes.content} html-text`}>
                    <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default TemplateContent;
