import { FC } from "react";
import TemplateImageGallery from "shared/Template/ImageGallery/ImageGallery";
import CardContentWithList from "shared/Card/HeadingWithList/HeadingWithList";
import classes from "./element.module.scss";

interface ElementImageContentProps {
    element?: any;
}

const ElementImageContent: FC<ElementImageContentProps> = ({ element }) => {
    const { title, images, html } = element as any;

    return (
        <TemplateImageGallery
            heading={title}
            pictures={images.edges.map((image: any) => image.node)}
        >
            <div className={classes.wrapper}>
                <CardContentWithList data={html} />
            </div>
        </TemplateImageGallery>
    );
};

export default ElementImageContent;
