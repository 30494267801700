import { ReactNode, FC } from "react";
import Header from "./Header/Header";
import classes from "./layout.module.scss";

interface layoutProps {
    children: ReactNode;
}

const Layout: FC<layoutProps> = ({ children }) => {
    return (
        <div className={classes.wrapper}>
            <Header />
            <main>{children}</main>
        </div>
    );
};

export default Layout;
