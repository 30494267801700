import {gql} from "@apollo/client";

const SITE_TREE = gql`
    query ReadPage($id: ID) {
      readPages(id: $id) {
        edges {
          node {
            id
            appRelativeLink
          }
        }
      }
    }
`;

export default SITE_TREE;
