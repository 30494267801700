import { FC } from "react";
import InterfacePieChart from "utilities/interface/elements/pieChart.interface";
import TemplateContentWithImage from "shared/Template/ContentWithImage/ContentWithImage";
import CardContentWithList from "shared/Card/HeadingWithList/HeadingWithList";
import CardContent from "shared/Card/Content/Content";
import classes from "./element.module.scss";

interface ElementPieChartProps {
    element: InterfacePieChart;
}

const ElementPieChart: FC<ElementPieChartProps> = ({ element }) => {
    const { title, chart, legend, slideDescription } = element;

    return (
        <TemplateContentWithImage heading={title} picture={chart.absoluteLink}>
            <div className={classes.wrapper}>
                <CardContentWithList data={legend} />
                <CardContent data={slideDescription} />
            </div>
        </TemplateContentWithImage>
    );
};

export default ElementPieChart;
