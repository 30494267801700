const FUNDING_ACTIVITIES = `
    ... on ElementFundingActivitySlide {
        id
        title
        subTitle
        ternaryTitle
        slideDescription
        secondaryDescription
        image {
            title
            name
            url
            absoluteLink
            width
            height
        }
    } `;

export default FUNDING_ACTIVITIES;
